<template>
  <div
    v-if="!loaded || categories.length > 0"
    :class="'categories ' + (compact ? 'compact' : '')"
  >
    <b-row cols="1" class="px-3">
      <b-col class="p-0 mx-2">
        <h4 class="mb-2">
          <strong>Categories</strong>
        </h4>
      </b-col>
    </b-row>
    <b-row
      cols="2"
      :cols-sm="compact ? 2 : 3"
      :cols-md="compact ? 2 : 4"
      class="px-3"
      align-v="stretch"
    >
      <b-col v-for="c in categories" :key="c.key" class="p-0 mb-3">
        <picker-item
          :to="'/category/' + c.key"
          :icon="c.icon"
          :icon-style="c.iconStyle"
          :name="c.name"
          :size="compact ? 'xs' : 'sm'"
        />
      </b-col>
    </b-row>

    <vc-loading v-if="!loaded">Loading Categories...</vc-loading>

    <div v-if="error" class="text-center text-muted">
      <h6>Error Loading Categories</h6>
    </div>
  </div>
</template>

<script>
import Enumerable from "linq";

const PickerItem = () =>
  import(/* webpackChunkName: "pickerItem" */ "./PickerItem.vue");

export default {
  name: "CategoryPicker",
  components: { PickerItem },
  props: {
    compact: { type: Boolean }
  },
  data() {
    return {
      categories: [],
      loaded: false,
      error: false
    };
  },
  watch: {
    loaded() {
      this.$emit("loaded");
    }
  },
  mounted() {
    this.$store
      .dispatch("fetchCategories")
      .then(
        d => {
          this.categories = Enumerable.from(d)
            .where(c => c.active)
            .orderBy(c => !c.featured)
            .thenBy(c => c.name)
            .toArray();
        },
        () => {
          this.error = true;
        }
      )
      .finally(() => (this.loaded = true));
  }
};
</script>

<style scoped lang="scss">
::v-deep .card-body {
  padding: 10px;
}
</style>