<template>
  <div v-if="show" :class="'favorites ' + (compact ? 'compact' : '')">
    <b-row cols="1" class="px-3">
      <b-col class="p-0 mx-2">
        <h4 class="mb-1">
          <strong>My Favorites</strong>
        </h4>
      </b-col>
    </b-row>

    <b-row cols="2" :cols-md="compact ? 2 : 3" class="px-3" align-v="stretch">
      <b-col :title="v.name" v-for="v in vendors" :key="v.key" class="p-0 mb-2">
        <picker-item
          :to="{
            name: 'Vendor',
            params: {
              vendorKey: v.key,
              vendorList: vendors
            }
          }"
          :name="v.name"
          :image="v.logoSrc"
          :size="compact ? 'xs' : 'sm'"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Enumerable from "linq";

const PickerItem = () =>
  import(/* webpackChunkName: "pickerItem" */ "./PickerItem.vue");

export default {
  name: "FavoritePicker",
  components: {
    PickerItem
  },
  props: {
    compact: { type: Boolean }
  },
  computed: {
    vendors() {
      if (!this.$store.state.favorites) return [];
      return Enumerable.from(this.$store.state.favorites)
        .orderBy(v => v.name.toLowerCase().replace("the ", ""))
        .toArray();
    },
    show() {
      return this.vendors.length > 0;
    }
  },
  mounted() {
    if (!this.$store.state.favorites) this.$store.dispatch("fetchFavorites");
  }
};
</script>

<style scoped lang="scss">
::v-deep .card-body {
  padding: 10px;
}

::v-deep img {
  max-height: 50px;
}

.compact ::v-deep img {
  max-height: 35px;
}
</style>