<template>
  <div
    id="search-widget"
    :class="'platter search p-2 p-sm-3 mb-4 ' + (compact ? 'compact' : '')"
  >
    <div class="content">
      <h3 class="text-center mb-3">Search for vendors</h3>
      <b-form action="." @submit.prevent="doSearch">
        <b-input
          ref="searchField"
          type="search"
          :size="compact ? undefined : 'lg'"
          :placeholder="searchPlaceholder"
          class="mb-2"
          autocomplete="off"
          @input="doInstantSearch"
          v-model="query"
        />
        <b-button
          block
          :size="compact ? 'sm' : 'lg'"
          variant="primary"
          type="submit"
          class="d-none d-sm-block"
        >
          <font-awesome-icon icon="search" />
          Search
        </b-button>
        <b-button
          block
          :size="compact ? 'sm' : undefined"
          variant="primary"
          type="submit"
          class="d-block d-sm-none"
        >
          <font-awesome-icon icon="search" />
          Search
        </b-button>
      </b-form>

      <transition name="fade">
        <div v-if="showInstant" class="instant mt-3">
          <b-row>
            <b-col
              cols="12"
              :sm="compact ? 12 : 6"
              :class="{ 'mb-2': true, 'mb-sm-0': !compact }"
              v-if="vendorsToDisplay.length > 0"
            >
              <div>
                <p class="mb-1">
                  <small>
                    <strong>Vendors</strong>
                  </small>
                </p>
                <transition-group name="custom" enter-active-class="fadeIn">
                  <p v-for="v in vendorsToDisplay" :key="v.name" class="mb-2">
                    <b-link
                      :to="{
                        name: 'Vendor',
                        params: {
                          vendorKey: v.key,
                          vendorList: vendorsToDisplay,
                          routeData: {
                            fullPath: '/search/' + query,
                            name: 'Search'
                          }
                        }
                      }"
                    >
                      {{ v.name }}
                    </b-link>
                  </p>
                </transition-group>
                <b-link
                  :to="'/search/' + query"
                  v-if="vendors.length > vendorsToDisplay.length"
                >
                  <strong>See More Vendors...</strong>
                </b-link>
              </div>
            </b-col>
            <br
              v-if="
                vendorsToDisplay.length > 0 && categoriesToDisplay.length > 0
              "
            />
            <b-col
              cols="12"
              :sm="compact ? 12 : 6"
              v-if="categoriesToDisplay.length > 0"
            >
              <div>
                <p class="mb-1">
                  <small><strong>Categories</strong></small>
                </p>
                <transition-group name="custom" enter-active-class="fadeIn">
                  <p v-for="c in categoriesToDisplay" :key="c.key" class="mb-2">
                    <b-link :to="'/category/' + c.key">
                      <font-awesome-icon :icon="c.icon" fixed-width size="lg" />
                      {{ c.name }}
                    </b-link>
                  </p>
                </transition-group>
                <b-link
                  :to="'/search/' + query"
                  v-if="categories.length > categoriesToDisplay.length"
                >
                  <strong>See More Categories...</strong>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Enumerable from "linq";
import _ from "lodash";

export default {
  name: "SearchWidget",
  props: {
    compact: { type: Boolean }
  },
  data() {
    return {
      searchPlaceholder: "",
      query: "",
      vendors: [],
      categories: [],
      placeholders: [],
      loaded: false
    };
  },
  computed: {
    showInstant() {
      return this.query && (this.hasVendors || this.hasCategories);
    },
    vendorsToDisplay() {
      return Enumerable.from(this.vendors)
        .take(3)
        .toArray();
    },
    categoriesToDisplay() {
      return Enumerable.from(this.categories)
        .take(3)
        .toArray();
    },
    hasVendors() {
      return (this.vendors?.length ?? 0) > 0;
    },
    hasCategories() {
      return (this.categories?.length ?? 0) > 0;
    }
  },
  watch: {
    loaded() {
      this.$emit("loaded");
    }
  },
  mounted() {
    this.$store
      .dispatch("fetchSearchPlaceholders")
      .then(ph => {
        this.placeholders = ph;

        if (!ph || ph.length === 0) return;

        let searchPlaceholderIndex = 0;
        this.searchPlaceholder = `try "${this.placeholders[searchPlaceholderIndex]}"`;

        setInterval(() => {
          searchPlaceholderIndex += 1;
          if (this.placeholders.length === searchPlaceholderIndex)
            searchPlaceholderIndex = 0;
          this.searchPlaceholder = `try "${this.placeholders[searchPlaceholderIndex]}"`;
        }, 5000);
      })
      .finally(() => (this.loaded = true));
  },
  methods: {
    doInstantSearch: _.debounce(function() {
      this.$gtag.event("search", {
        search_term: this.query
      });
      this.$store
        .dispatch("fetchSearch", { query: this.query, instant: true })
        .then(r => {
          this.vendors = r.vendors;
          this.categories = r.categories;
        });
    }, 175),
    doSearch() {
      if (!this.query.trim()) {
        this.query = "";
        this.$refs.searchField.focus();
        return;
      }
      this.$router.push({ name: "Search", params: { query: this.query } });
    }
  }
};
</script>

<style scoped lang="scss">
#search-widget {
  backdrop-filter: blur(5px);
}

.content {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-size: 1.25em;

    @media (min-width: 576px) {
      font-size: 1.65em;
    }
  }

  .compact & {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .compact & h3 {
    font-size: 1.1em;
  }

  .btn {
    border-style: solid;
    border-width: 2px;
  }

  @media (min-width: 576px) {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px !important;
    padding-right: 30px !important;

    .compact & {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.instant {
  font-size: 0.8em;
  margin-bottom: -10px;

  @media (min-width: 576px) {
    margin-bottom: -40px;
  }

  .compact & {
    margin-bottom: -10px;
  }

  small {
    text-transform: uppercase;
    font-size: 0.95em;
    letter-spacing: 0.5px;
    font-weight: 900;
  }

  a {
    font-size: 1.3em;
  }
}
</style>