<template>
  <div v-if="!loaded || collections.length > 0" id="collection-picker">
    <div class="content">
      <b-row cols="1" class="px-3">
        <b-col class="p-0 mx-2 mb-1">
          <h4 class="mb-0">
            <strong>Collections</strong>
          </h4>
          <p class="mb-0 text-muted">
            Discover curated vendors based on guided questions.
          </p>
        </b-col>
      </b-row>

      <b-row cols="2" :cols-md="compact ? 2 : 3" class="px-3">
        <b-col v-for="c in collections" :key="c.key" class="p-0 mb-2">
          <picker-item
            :to="'/collection/' + c.key"
            :icon="c.icon"
            :name="c.name"
            :size="compact ? 'xs' : ''"
          />
        </b-col>
      </b-row>

      <vc-loading v-if="!loaded">Loading Collections...</vc-loading>

      <div v-if="error" class="text-center text-muted">
        <h6>Error Loading Collections</h6>
      </div>
    </div>
  </div>
</template>

<script>
import Enumerable from "linq";

const PickerItem = () =>
  import(/* webpackChunkName: "pickerItem" */ "./PickerItem.vue");

export default {
  name: "CollectionPicker",
  components: { PickerItem },
  props: {
    compact: { type: Boolean }
  },
  data() {
    return {
      collections: [],
      loaded: false,
      error: false
    };
  },
  watch: {
    loaded() {
      this.$emit("loaded");
    }
  },
  mounted() {
    this.$store
      .dispatch("fetchCollections")
      .then(
        d => {
          this.collections = Enumerable.from(d)
            .orderBy(c => !c.featured)
            .thenBy(c => c.name)
            .toArray();
        },
        () => {
          this.error = true;
        }
      )
      .finally(() => (this.loaded = true));
  }
};
</script>

<style scoped lang="scss">
::v-deep .card h4 {
  font-weight: 800 !important;
}
</style>