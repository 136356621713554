var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'platter search p-2 p-sm-3 mb-4 ' + (_vm.compact ? 'compact' : ''),attrs:{"id":"search-widget"}},[_c('div',{staticClass:"content"},[_c('h3',{staticClass:"text-center mb-3"},[_vm._v("Search for vendors")]),_c('b-form',{attrs:{"action":"."},on:{"submit":function($event){$event.preventDefault();return _vm.doSearch.apply(null, arguments)}}},[_c('b-input',{ref:"searchField",staticClass:"mb-2",attrs:{"type":"search","size":_vm.compact ? undefined : 'lg',"placeholder":_vm.searchPlaceholder,"autocomplete":"off"},on:{"input":_vm.doInstantSearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('b-button',{staticClass:"d-none d-sm-block",attrs:{"block":"","size":_vm.compact ? 'sm' : 'lg',"variant":"primary","type":"submit"}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Search ")],1),_c('b-button',{staticClass:"d-block d-sm-none",attrs:{"block":"","size":_vm.compact ? 'sm' : undefined,"variant":"primary","type":"submit"}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Search ")],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showInstant)?_c('div',{staticClass:"instant mt-3"},[_c('b-row',[(_vm.vendorsToDisplay.length > 0)?_c('b-col',{class:{ 'mb-2': true, 'mb-sm-0': !_vm.compact },attrs:{"cols":"12","sm":_vm.compact ? 12 : 6}},[_c('div',[_c('p',{staticClass:"mb-1"},[_c('small',[_c('strong',[_vm._v("Vendors")])])]),_c('transition-group',{attrs:{"name":"custom","enter-active-class":"fadeIn"}},_vm._l((_vm.vendorsToDisplay),function(v){return _c('p',{key:v.name,staticClass:"mb-2"},[_c('b-link',{attrs:{"to":{
                      name: 'Vendor',
                      params: {
                        vendorKey: v.key,
                        vendorList: _vm.vendorsToDisplay,
                        routeData: {
                          fullPath: '/search/' + _vm.query,
                          name: 'Search'
                        }
                      }
                    }}},[_vm._v(" "+_vm._s(v.name)+" ")])],1)}),0),(_vm.vendors.length > _vm.vendorsToDisplay.length)?_c('b-link',{attrs:{"to":'/search/' + _vm.query}},[_c('strong',[_vm._v("See More Vendors...")])]):_vm._e()],1)]):_vm._e(),(
              _vm.vendorsToDisplay.length > 0 && _vm.categoriesToDisplay.length > 0
            )?_c('br'):_vm._e(),(_vm.categoriesToDisplay.length > 0)?_c('b-col',{attrs:{"cols":"12","sm":_vm.compact ? 12 : 6}},[_c('div',[_c('p',{staticClass:"mb-1"},[_c('small',[_c('strong',[_vm._v("Categories")])])]),_c('transition-group',{attrs:{"name":"custom","enter-active-class":"fadeIn"}},_vm._l((_vm.categoriesToDisplay),function(c){return _c('p',{key:c.key,staticClass:"mb-2"},[_c('b-link',{attrs:{"to":'/category/' + c.key}},[_c('font-awesome-icon',{attrs:{"icon":c.icon,"fixed-width":"","size":"lg"}}),_vm._v(" "+_vm._s(c.name)+" ")],1)],1)}),0),(_vm.categories.length > _vm.categoriesToDisplay.length)?_c('b-link',{attrs:{"to":'/search/' + _vm.query}},[_c('strong',[_vm._v("See More Categories...")])]):_vm._e()],1)]):_vm._e()],1)],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }